/*!

=========================================================
* Paper Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import NotificationAlert from "react-notification-alert";
import BootstrapTable from 'react-bootstrap-table-next';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label, FormText
} from "reactstrap";

var DatePicker = require("reactstrap-date-picker");

const columns = [{
  dataField: 'id',
  text: 'Id'
}, {
  dataField: 'name',
  text: 'Name'
}, {
  dataField: 'type',
  text: 'Type'
}];

const selectRow = {
  mode: 'checkbox',
  clickToSelect: true
};

class Alarm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      alarmType: "-1",
      alarms: [],
      dropdownOpen: false,
      visible: true,
      alarmName: ''
    }

    this.onAlarmTypeChange = this.onAlarmTypeChange.bind(this);
    this.onDailyReportChange = this.onDailyReportChange.bind(this);
    this.onWeeklyReportChange = this.onWeeklyReportChange.bind(this);
    this.onMonthlyReportChange = this.onMonthlyReportChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.notify = this.notify.bind(this);
    this.notificationAlert = React.createRef();
    this.onNameChange = this.onNameChange.bind(this);
    this.onNokCountChange = this.onNokCountChange.bind(this);
    this.onNokHoursChange = this.onNokHoursChange.bind(this);
    this.onStoppedHoursChange = this.onStoppedHoursChange.bind(this);
  }


  notify(place, type, message) {
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>
            {message}
          </div>
        </div>
      ),
      type: type,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 7,
    };
    this.notificationAlert.current.notificationAlert(options);
  }

  onNokCountChange(event) {
    this.setState({ nokCount: event.target.value });
  }

  onNokHoursChange(event) {
    this.setState({ nokHours: event.target.value });
  }

  onStoppedHoursChange(event) {
    this.setState({ stoppedHours: event.target.value });
  }
  onNameChange(event) {
    this.setState({ alarmName: event.target.value });
  }

  onDailyReportChange(event) {
    this.setState({ dailyReport: event.target.checked });
  }

  onWeeklyReportChange(event) {
    this.setState({ weeklyReport: event.target.checked });
  }

  onMonthlyReportChange(event) {
    this.setState({ monthlyReport: event.target.checked });
  }

  onAlarmTypeChange(event) {
    this.setState({ alarmType: event.target.value });
  }

  handleChange(cmp, value, formattedValue) {
    this.setState({
      beginDate: value, // ISO String, ex: "2016-11-19T12:00:00.000Z"
      beginDateFormattedValue: formattedValue // Formatted String, ex: "11/19/2016"
    });
  }

  handleSubmit() {
    if (!this.state.alarmName) {
      this.notify("br", "warning", "Display name is empty.");
      return;
    }
    if (this.state.alarmType == -1) {
      this.notify("br", "warning", "Select alarm type");
      return;
    }

    if (this.state.alarmType == "nok") {
      if (!this.state.nokCount) {
        this.notify("br", "warning", "Provide NOK count.");
        return;
      }
      if (!this.state.nokHours) {
        this.notify("br", "warning", "Provide NOK hours.");
        return;
      }
    }

    if (this.state.alarmType == "stop") {
      if (!this.state.stoppedHours) {
        this.notify("br", "warning", "Provide stopped hours.");
        return;
      }
    }

    if (this.state.alarmType == "report") {
      if (!this.state.dailyReport && !this.state.weeklyReport && !this.state.monthlyReport) {
        this.notify("br", "warning", "Provide at least one period for reporting.");
        return;
      }
    }

    if (!this.state.beginDate) {
      this.notify("br", "warning", "Enter a begin date.");
      return;
    }

    const alarms = this.state.alarms;
    alarms.push({ id: alarms.length + 1, name: this.state.alarmName, type: this.state.alarmType });
    this.setState({ alarms });
    this.notify("br", "success", "Alarm created.");

  }

  render() {
    return (
      <>
        <div className="content">
          <NotificationAlert ref={this.notificationAlert} />
          <Row>
            <Col md="4">
              <Card>
                <CardHeader>
                  <CardTitle tag="h5">Create Alarm</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col className="pr-1" md="12">
                      <label>Name</label>
                      <Input
                        onChange={this.onNameChange}
                        value={this.state.alarmName}
                        placeholder="Name"
                        type="text"
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 12 }}>
                    <Col className="pr-1" md="12">
                      <label>Alarm Type</label>
                      <select name="alarms" id="alarms" style={{ width: '100%' }} onChange={this.onAlarmTypeChange} value={this.state.alarmType}>
                        <option value="-1">Select</option>
                        <option value="nok">Too many NOK</option>
                        <option value="stop">Line is stopped</option>
                        <option value="report">OK/NOK Reports</option>
                      </select>
                    </Col>
                  </Row>
                  {
                    this.state.alarmType === "nok" && (
                      <div>
                        <Row style={{ marginTop: 12 }}>
                          <Col className="pr-1" md="12">
                            <label>If NOK count greater than</label>
                            <Input type="number"
                              onChange={this.onNokCountChange}
                              value={this.state.nokCount} />
                          </Col>
                        </Row>
                        <Row style={{ marginTop: 12 }}>
                          <Col className="pr-1" md="12">
                            <label>For hours</label>
                            <Input type="number"
                              onChange={this.onNokHoursChange}
                              value={this.state.nokHours} />
                          </Col>
                        </Row>
                      </div>
                    )
                  }
                  {
                    this.state.alarmType === "stop" && (
                      <div>
                        <Row style={{ marginTop: 12 }}>
                          <Col className="pr-1" md="12">
                            <label>Line is stopped for hours</label>
                            <Input type="number"
                              onChange={this.onStoppedHoursChange}
                              value={this.state.stoppedHours} />
                          </Col>
                        </Row>
                      </div>
                    )
                  }
                  {
                    this.state.alarmType === "report" && (
                      <Row style={{ marginTop: 12 }}>
                        <Col className="pr-1" md="4">
                          <label>Daily</label>
                          <Input type="checkbox" style={{ marginLeft: 20 }} value={this.state.dailyReport} onChange={this.onDailyReportChange} />
                        </Col>
                        <Col className="pr-1" md="4">
                          <label>Weekly</label>
                          <Input type="checkbox" style={{ marginLeft: 20 }} value={this.state.weeklyReport} onChange={this.onWeeklyReportChange} />
                        </Col>
                        <Col className="pr-1" md="4">
                          <label>Monthly</label>
                          <Input type="checkbox" style={{ marginLeft: 20 }} value={this.state.monthlyReport} onChange={this.onMonthlyReportChange} />
                        </Col>
                      </Row>
                    )
                  }
                  <Row style={{ marginTop: 12 }}>
                    <Col>
                      <label>Start Date</label>
                      <DatePicker id="example-datepicker-1"
                        name="beginDate"
                        type="datetime-local"
                        value={this.state.beginDate}
                        showClearButton={false}
                        onChange={(v, f) => this.handleChange('begin', v, f)} /></Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="12">
                      <Button
                        className="btn-round"
                        color="primary"
                        type="button"
                        style={{ width: '100%', marginTop: 24 }}
                        onClick={this.handleSubmit}
                      >
                        Save
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            {
              this.state.alarms.length > 0 && (
                <Col md="8">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h5">Alarms</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <BootstrapTable
                        striped
                        hover
                        condensed
                        bootstrap4
                        keyField='id'
                        data={this.state.alarms}
                        columns={columns}
                        selectRow={selectRow}
                      />
                    </CardBody>
                  </Card>
                </Col>
              )
            }
          </Row>

        </div >
      </>
    );
  }
}

export default Alarm;