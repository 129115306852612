/*!

=========================================================
* Paper Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Input,
  Row,
  Col,

} from "reactstrap";
import ApexCharts from 'react-apexcharts'

var DatePicker = require("reactstrap-date-picker");

class Part extends React.Component {
  constructor(props) {
    super(props)

    var beginDate = new Date();
    beginDate.setDate(beginDate.getDate() - 7);

    this.state = {
      beginDate: beginDate.toISOString(),
      endDate: new Date().toISOString(),
      hour: {

        series: [{
          name: "Line Work Time",
          data: [12, 13, 16, 17, 20, 10, 7]
        }],
        options: {
          chart: {
            type: 'area',
            height: 350,
            zoom: {
              enabled: false
            },
            toolbar: {
              show: false
            }
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'straight'
          },

          labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
          yaxis: {
            opposite: true
          },
          legend: {
            horizontalAlign: 'left'
          }
        },
      },
      line: {

        series: [
          {
            name: "OK",
            data: [28, 29, 33, 36, 32, 32, 33]
          },
          {
            name: "NOK",
            data: [12, 11, 14, 18, 17, 13, 13]
          }
        ],
        options: {
          chart: {
            height: 350,
            type: 'line',
            dropShadow: {
              enabled: true,
              color: '#000',
              top: 18,
              left: 7,
              blur: 10,
              opacity: 0.2
            },
            toolbar: {
              show: false
            }
          },
          colors: ['#77B6EA', '#545454'],
          dataLabels: {
            enabled: true,
          },
          stroke: {
            curve: 'smooth'
          },

          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          markers: {
            size: 1
          },
          xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],

          },
          yaxis: {

            min: 5,
            max: 40
          },

        },
      },
      mix: {

        series: [{
          name: 'Print',
          data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
        }, {
          name: 'Surface',
          data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
        }],
        options: {
          theme: {
            palette: 'palette1' // upto palette10
          },
          chart: {
            type: 'bar',
            height: 350,
            toolbar: {
              show: false
            }
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
              endingShape: 'rounded'
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          xaxis: {
            categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
          },
          fill: {
            opacity: 1
          },

        },


      },
      pie: {
        series: [44, 55, 41, 17],
        options: {
          chart: {
            type: 'donut',
          },
          labels: ["Camera1", "Camera2", "Camera3", "Camera4"],
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
          }]
        },
      },
      bar: {
        series: [{
          data: [44,]
        }, {
          data: [53]
        }],
        options: {
          chart: {
            type: 'bar',
            height: 200
          },
          labels: ["print", "surface"],

        },
      }
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(cmp, value, formattedValue) {
    if (cmp == 'begin') {
      this.setState({
        beginDate: value, // ISO String, ex: "2016-11-19T12:00:00.000Z"
        beginDateFormattedValue: formattedValue // Formatted String, ex: "11/19/2016"
      });
    } else {
      this.setState({
        endDate: value, // ISO String, ex: "2016-11-19T12:00:00.000Z"
        endDateFormattedValue: formattedValue // Formatted String, ex: "11/19/2016"
      });
    }
  }

  async handleSubmit(event) {
    this.setState({ data: true });
  }

  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col md={12}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Query</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="3">
                      <label>Part Name</label>
                      <Input
                        name='partName'
                        placeholder="Part Name"
                        type="text"
                      />
                    </Col>
                    <Col md="3">
                      <label>Begin</label>
                      <DatePicker id="example-datepicker-1"
                        name="beginDate"
                        type="datetime-local"
                        value={this.state.beginDate}
                        showClearButton={false}
                        onChange={(v, f) => this.handleChange('begin', v, f)} />
                    </Col>
                    <Col md="3">
                      <label>End</label>
                      <DatePicker id="example-datepicker-2"
                        name="endDate"
                        value={this.state.endDate}
                        style={{ width: '10px' }}

                        showClearButton={false}
                        onChange={(v, f) => this.handleChange('end', v, f)} />
                    </Col>
                    <Col md="3">
                      <Button
                        className="btn-round"
                        style={{ marginTop: 25 }}
                        color="primary"
                        type="button"
                        onClick={this.handleSubmit}
                      >
                        Search
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-success">
                        <i className="nc-icon nc-check-2 text-success" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Total Good</p>
                        <CardTitle tag="p">13.230</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                  <CardFooter>
                </CardFooter>
                </CardBody>
              </Card>
            </Col>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-danger">
                        <i className="nc-icon nc-simple-remove text-danger" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Total Bad</p>
                        <CardTitle tag="p">1.432</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                  <CardFooter>
                </CardFooter>
                </CardBody>
              </Card>
            </Col>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-info">
                        <i className="nc-icon nc-chart-pie-36 text-info" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Good Ratio</p>
                        <CardTitle tag="p">% 76.65</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-watch-time text-warning" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Avg. Control Time</p>
                        <CardTitle tag="p">1.7</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                  <CardFooter>
                </CardFooter>
                </CardBody>
              </Card>
            </Col>
          
          </Row>
          <Row>
            <Col md="6">
              <Card className="card-chart">
                <CardHeader>
                  <CardTitle tag="h5">OK / NOK</CardTitle>
                  <p className="card-category">Last Year</p>
                </CardHeader>
                <CardBody>
                  <ApexCharts options={this.state.line.options} series={this.state.line.series} type="line" height={350} />
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fa fa-calendar" /> Number of error types.
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col md="6">
              <Card className="card-chart">
                <CardHeader>
                  <CardTitle tag="h5">Error Types</CardTitle>
                  <p className="card-category">Last Year</p>
                </CardHeader>
                <CardBody>
                  <ApexCharts options={this.state.mix.options} series={this.state.mix.series} type="bar" height={350} />
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fa fa-calendar" /> Number of error types.
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row>

        </div>
      </>
    );
  }
}

export default Part;
