/*!

=========================================================
* Paper Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
} from "reactstrap";

import ApexCharts from 'react-apexcharts'

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hours: {
        series: [
          {
            name: "Station 1",
            data: [8, 10, 12, 13, 16, 7, 9, 12, 6, 4],
            min: 0,
            max: 24
          },
          {
            name: "Station 2",
            data: [7, 12, 7, 4, 11, 9, 6, 4, 8, 10],
            min: 0,
            max: 24
          }],
        options: {
          chart: {
            height: 350,
            type: 'line',
            zoom: {
              enabled: false
            },
            toolbar: {
              show: false
            },
            dataLabels: {
              enabled: false,
            }
          },
          theme: {
            palette: 'palette2' // upto palette10
          },
          stroke: {
            curve: 'smooth'
          },
          markers: {
            size: 4,
            hover: {
              sizeOffset: 6
            }
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          xaxis: {
            categories: ['01 Jan', '02 Jan', '03 Jan', '04 Jan', '05 Jan', '06 Jan', '07 Jan', '08 Jan', '09 Jan', '10 Jan']
          },
        },


      },
      pie: {
        series: [44, 55, 13, 43, 22],
        options: {
          chart: {
            width: 380,
            type: 'pie',
          },
          theme: {
            palette: 'palette3' // upto palette10
          },
          legend: {
            position: 'bottom'
          },
          labels: ['Model A', 'Model B', 'Model C', 'Model D', 'Model E'],
        },
      },
      line: {

        series: [{
          name: 'OK',
          data: [90, 96, 86, 98, 42, 109, 100, 130, 150, 125]
        }, {
          name: 'NOK',
          data: [11, 32, 45, 32, 96, 52, 41, 21, 46, 65]
        }],
        options: {
          chart: {
            height: 350,
            type: 'area'
          },
          theme: {
            palette: 'palette5' // upto palette10
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'smooth'
          },
          xaxis: {
            type: 'datetime',
            categories: ['01 Jan 2001', '02 Jan 2001', '03 Jan 2001', '04 Jan 2001', '05 Jan 2001', '06 Jan 2001', '07 Jan 2001', '08 Jan 2001', '09 Jan 2001', '10 Jan 2001']
          },
        },


      },
      avg: {
        series: [{
          name: "Control Time (sec)",
          data: [1.2, 2.5, 1.7, 2.5, 2.3, 3.6, 2.5, 1.8, 2.2, 1.7]
        }],
        options: {
          chart: {
            height: 350,
            type: 'line',
            zoom: {
              enabled: false
            },
            toolbar: {
              show: false
            },
            dataLabels: {
              enabled: false,
            }
          },
          theme: {
            palette: 'palette6' // upto palette10
          },
          markers: {
            size: 4,
            hover: {
              sizeOffset: 6
            }
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          xaxis: {
            categories: ['01 Jan', '02 Jan', '03 Jan', '04 Jan', '05 Jan', '06 Jan', '07 Jan', '08 Jan', '09 Jan', '10 Jan']
          }
        },
      }
    };
  }

  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-single-copy-04 text-warning" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Total Model</p>
                        <CardTitle tag="p">148</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fas fa-sync-alt" /> All Time
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-chart-bar-32 text-info" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Total Check</p>
                        <CardTitle tag="p">18.367</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fas fa-sync-alt" /> Last 10 Days
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-success">
                        <i className="nc-icon nc-check-2 text-success" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Total Good</p>
                        <CardTitle tag="p">13.230</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fas fa-sync-alt" /> Last 10 Days
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-danger">
                        <i className="nc-icon nc-simple-remove text-danger" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Total Bad</p>
                        <CardTitle tag="p">1.432</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fas fa-sync-alt" /> Last 10 Days
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <Card className="card-chart">
                <CardHeader>
                  <CardTitle tag="h5">Station Working Hours</CardTitle>
                  <p className="card-category">Last 10 Days</p>
                </CardHeader>
                <CardBody>
                  <ApexCharts options={this.state.hours.options} series={this.state.hours.series} type="line" height={300} />
                </CardBody>
              </Card>
            </Col>
            <Col md="6">
              <Card className="card-chart">
                <CardHeader>
                  <CardTitle tag="h5">Average Control Time</CardTitle>
                  <p className="card-category">Last 10 Days</p>
                </CardHeader>
                <CardBody>
                  <ApexCharts options={this.state.avg.options} series={this.state.avg.series} type="line" height={300} />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="8">
              <Card className="card-chart">
                <CardHeader>
                  <CardTitle tag="h5">OK-NOK Statistics</CardTitle>
                  <p className="card-category">Last 10 Days</p>
                </CardHeader>
                <CardBody>
                  <ApexCharts options={this.state.line.options} series={this.state.line.series} type="bar" height={350} />
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card className="card-chart">
                <CardHeader>
                  <CardTitle tag="h5">Bad Statistics by Model</CardTitle>
                  <p className="card-category">Last 10 Days</p>
                </CardHeader>
                <CardBody>
                  <ApexCharts options={this.state.pie.options} series={this.state.pie.series} type="pie" height={400} />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
          </Row>
        </div>
      </>
    );
  }
}

export default Dashboard;
