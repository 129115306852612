// Config object to be passed to Msal on creation
export const msalConfig = {
    auth: {
        authority: 'https://login.microsoftonline.com/f9e78723-a1ee-4c35-9988-4a1f70e87108',
        clientId: "c3e77819-047e-4f32-84b7-7b379bad1876",
        redirectUri: "https://dashboard.visiomex.com/",
        postLogoutRedirectUri: "https://dashboard.visiomex.com/"
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false
    },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
    scopes: ["User.Read"]
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};
