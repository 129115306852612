/*!

=========================================================
* Paper Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Button,
} from "reactstrap";

var DatePicker = require("reactstrap-date-picker");

class Error extends React.Component {
  constructor(props) {
    super(props)

    var beginDate = new Date();
    beginDate.setDate(beginDate.getDate() - 7);

    this.state = {
      beginDate: beginDate.toISOString(),
      endDate: new Date().toISOString(),
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(cmp, value, formattedValue) {
    if (cmp == 'begin') {
      this.setState({
        beginDate: value, // ISO String, ex: "2016-11-19T12:00:00.000Z"
        beginDateFormattedValue: formattedValue // Formatted String, ex: "11/19/2016"
      });
    } else {
      this.setState({
        endDate: value, // ISO String, ex: "2016-11-19T12:00:00.000Z"
        endDateFormattedValue: formattedValue // Formatted String, ex: "11/19/2016"
      });
    }
  }

  async handleSubmit(event) {
    this.setState({ data: true });
  }


  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col md={12}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Query</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="3">
                      <label>Begin</label>
                      <DatePicker id="example-datepicker-1"
                        name="beginDate"
                        type="datetime-local"
                        value={this.state.beginDate}
                        showClearButton={false}
                        onChange={(v, f) => this.handleChange('begin', v, f)} />
                    </Col>
                    <Col md="3">
                      <label>End</label>
                      <DatePicker id="example-datepicker-2"
                        name="endDate"
                        value={this.state.endDate}
                        style={{ width: '10px' }}

                        showClearButton={false}
                        onChange={(v, f) => this.handleChange('end', v, f)} />
                    </Col>
                    <Col md="3">
                      <Button
                        className="btn-round"
                        style={{ marginTop: 25 }}
                        color="primary"
                        type="button"
                        onClick={this.handleSubmit}
                      >
                        Search
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">User Actions</CardTitle>
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th>Username</th>
                        <th>Error</th>
                        <th >Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Dakota Rice</td>
                        <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur in nisi est. Integer iaculis placerat elit. Morbi consectetur tellus justo, non mattis eros tempor nec. </td>
                        <td>01 Jan 2020 09:00</td>
                      </tr>
                      <tr>
                        <td>Dakota Rice</td>
                        <td>Maecenas massa nisl, placerat eu semper eu, eleifend ac justo. Vivamus hendrerit eleifend metus, vel posuere lectus suscipit convallis.</td>
                        <td>01 Jan 2020 09:02</td>
                      </tr>
                      <tr>
                        <td>Dakota Rice</td>
                        <td>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Integer a magna nec tellus euismod dapibus. </td>
                        <td>01 Jan 2020 09:03</td>
                      </tr>
                      
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Error;
