/*!

=========================================================
* Paper Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch, Redirect } from "react-router-dom";

import NavigationBar from "components/Navbars/NavigationBar.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";
// import LoginPage from "../views/Login";
import { useMsal, useAccount } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import LoginPage from "../views/Login";

function Dashboard(props) {
  const mainPanel = React.createRef();

  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});

  const login = () => {
    instance.loginRedirect();
  }

  const [name, setName] = useState(null);

  useEffect(() => {
    if (accounts.length > 0) {
      setName(accounts[0].name.split(" ")[0]);
    }
  }, [accounts]);

  const logout = () => {
    instance.logout();
  }

  if (inProgress === "none" && account) {
    return (
      <div className="wrapper">
        <Sidebar
          {...props}
          routes={routes}
          bgColor="white"
          activeColor="info"
        />
        <div className="main-panel" ref={mainPanel}>
          <NavigationBar {...props} username={name} logout={logout} />
          <Switch>
            {routes.map((prop, key) => {
              return (
                <Route
                  path={prop.path}
                  component={prop.component}
                  key={key}
                />
              );
            })}
            <Redirect to="/dashboard" />
          </Switch>
        </div>

      </div>
    );
  }

  return (
    <div style={{ margin: 10 }}>
      Selam, bu sayfayı henüz tasarlayamadık ama bir şekilde login olman gerek. :)  <a href="#" onClick={login}>Buraya</a> tıklayarak login olabilirsin...
    </div>
  );


}

export default Dashboard;
