import React from "react";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.2.0";
import "assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";

import AdminLayout from "layouts/Admin.js";
import { MsalProvider } from "@azure/msal-react";

const hist = createBrowserHistory();

export default function App({ pca }) {
    return (
        <Router history={hist}>
            <MsalProvider instance={pca}>
                <Switch>
                    <Route path="/" render={(props) => <AdminLayout {...props} />} />
                    <Redirect to="/dashboard" />
                </Switch>
            </MsalProvider>
        </Router>
    );
}

